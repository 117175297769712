$(document).ready(function () {
  $(".loader__wrapper-one").addClass("active");
});

$(window).on("load", function () {
  $(".loader").addClass("end");
});

// ======= sticky header =======
const header = document.getElementById("yh-header");
let lastScrollY = window.scrollY;

window.addEventListener("scroll", () => {
  if (lastScrollY < window.scrollY) {
    header.classList.add("header--hidden");
  } else {
    header.classList.remove("header--hidden");
  }

  lastScrollY = window.scrollY;
});

window.addEventListener("scroll", () => {
  if (window.scrollY > 50) {
    header.classList.add("sticky");
  } else {
    header.classList.remove("sticky");
  }
});

// =========== sticky process =================
window.addEventListener("load", () => {
  // Retrieve all help sections
  const sections = Array.from(document.querySelectorAll("section[id]"));
 
  // Once a scrolling event is detected, iterate all elements
  // whose visibility changed and highlight their navigation entry
  const scrollHandler = entries =>
   entries.forEach(entry => {
    const section = entry.target;
    const sectionId = section.id;
    const sectionLink = document.querySelector(`a[href="#${sectionId}"]`);

    if (entry.intersectionRatio > 0) {
     section.classList.add("visible");
     sectionLink.classList.add("visible");
    } else {
     section.classList.remove("visible");
     sectionLink.classList.remove("visible");
    }
   });
 
  // Creates a new scroll observer
  const observer = new IntersectionObserver(scrollHandler);
 
  //noinspection JSCheckFunctionSignatures
  sections.forEach(section => observer.observe(section));
 });

// ============= sidebar navigation toggle ==========================
var closeMenuBtn = document.getElementById("close-menu-btn");
var mobileMenu = document.getElementById("mobile-menu");
var navbarToggle = document.getElementById("navbar-toggle");
var mobileMenuOverlay = document.getElementById("mobile-menu-overlay");
var body = document.querySelector(".yh-body");

navbarToggle.onclick = function () {
  mobileMenu.classList.add("is-active");
  mobileMenuOverlay.classList.add("is-active");
  body.classList.add("disable-scroll");
};

closeMenuBtn.onclick = function () {
  mobileMenu.classList.remove("is-active");
  mobileMenuOverlay.classList.remove("is-active");
  body.classList.remove("disable-scroll");
};

// close the menu when clicked from outside
document.addEventListener(
  "click",
  () => {
    if (mobileMenu.classList.contains("is-active")) {
      mobileMenu.classList.remove("is-active");
      mobileMenuOverlay.classList.remove("is-active");
      body.classList.remove("disable-scroll");
    }
  },
  true
);

// ============= video scale gsap animation starts =========
gsap.registerPlugin(ScrollTrigger);

// --- ORANGE PANEL ---

const tl = gsap.timeline({
  scrollTrigger: {
    trigger: ".video-section",
    scrub: true,
    pin: true,

    start: "88.5% 88.5%",
    end: "+=100%",
  },
});

tl.from(".video-wrapper", {
  scale: 0.885,
  ease: "none",
});

// button toggle
$(document).on("click", ".toggle-sound", function (e) {
  $(this).toggleClass("sound-mute");
});

// youtube mute/unmute
var tag = document.createElement("script");
tag.src = "https://www.youtube.com/iframe_api";
var firstScriptTag = document.getElementsByTagName("script")[0];
firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

var player;

function onYouTubeIframeAPIReady() {
  player = new YT.Player("youtube-video", {
    events: {
      onReady: onPlayerReady,
      onStateChange: onPlayerStateChange,
    },
  });
}

function onPlayerReady() {
  console.log("");
}

function onPlayerStateChange() {
  console.log("");
}

var hostUrl = window.location.protocol + "//" + window.location.host + "/";
var muteButton = document.getElementById("mute");

if (document.URL == hostUrl || document.URL.includes("index") || document.URL.includes("web-design")) {
  muteButton.addEventListener("click", function (event) {
    if (player.isMuted()) {
      player.unMute();
    } else {
      player.mute();
    }
  });
}

// ============ logo scroll ===========================
gsap.to(".our-client__logo1", {
  xPercent: -100,
  x: innerWidth,
  ease: "none",
  scrollTrigger: {
    trigger: ".our-client__logo",
    start: "top center",
    end: "+=" + 2000,
    scrub: true,
    pin: false,
  },
});

gsap.to(".our-client__logo2", {
  xPercent: -50,
  x: innerWidth,
  ease: "none",
  scrollTrigger: {
    trigger: ".our-client__logo",
    start: "top center",
    end: "+=" + 1000,
    scrub: true,
    pin: false,
  },
});

gsap.to(".our-client__logo3", {
  xPercent: -100,
  x: innerWidth,
  ease: "none",
  scrollTrigger: {
    trigger: ".our-client__logo",
    start: "top center",
    end: "+=" + 2000,
    scrub: true,
    pin: false,
  },
});

// ========== text and hero animation =========
window.addEventListener("load", () => {
  // hero area
  const tl = gsap.timeline();

  tl.from(".header__logo", 1, {
    opacity: 0,
    y: -100,
    delay: 1,
    ease: Expo.easeInOut,
  });

  tl.from(".navbar-toggle", 1, {
    opacity: 0,
    y: -100,
    delay: -1,
    ease: Expo.easeInOut,
  });

  tl.from(".animate-hero-heading", {
    opacity: 0,
    duration: 1.2,
    y: 50,
    delay: -1.8,
    ease: Power3.easeInOut,
  });

  tl.from(".moving-logo__wrapper", {
    opacity: 0,
    scale: 0.9,
    ease: Power3.easeInOut,
    delay: -0.45,
  });

  tl.from(".banner-social-media__list-item", {
    opacity: 0,
    ease: Power3.easeInOut,
    stagger: 0.1,
    delay: -0.7,
    x: -20,
  });

  tl.from(".contact-form__social-media > .social-media-link", {
    opacity: 0,
    ease: Power3.easeInOut,
    stagger: 0.1,
    delay: -0.7,
    x: -20,
  });

  // about page animation
  tl.from(".hero__header", {
    opacity: 0,
    duration: 1.2,
    y: 50,
    delay: -0.5,
    ease: Power3.easeInOut,
  });

  tl.from(".animate-hero-image", 1, {
    opacity: 0,
    duration: 2.8,
    y: 30,
    delay: -2.3,
    ease: Power3.easeInOut,
  });

  tl.from(".animate--branding-hero-image", 1, {
    opacity: 0,
    duration: 2,
    y: 30,
    delay: -2.3,
    stagger: 0.1,
    ease: Power3.easeInOut,
  });
});

// animation on text container
const textContainers = gsap.utils.toArray(".animate-fade-in-up");
textContainers.forEach((textContainer, i) => {
  const anim = gsap.fromTo(
    textContainer,
    { opacity: 0, y: 50 },
    { duration: 1, opacity: 1, y: 0 }
  );
  ScrollTrigger.create({
    trigger: textContainer,
    animation: anim,
    toggleActions: "play",
    once: true,
    duration: 1,
    ease: Power4.easeOut,
  });
});

// animation on images
const images = gsap.utils.toArray(".animate-image-revel");
images.forEach((image, i) => {
  const animImage = gsap.from(image, {
    y: 30,
    opacity: 0.65,
    duration: 1,
  });
  ScrollTrigger.create({
    trigger: image,
    animation: animImage,
    toggleActions: "play",
    once: true,
    duration: 1,
    ease: Power4.easeOut,
  });
});

// animation of fade in for hero part
const fadeIn = gsap.utils.toArray(".animate-fade-in");
fadeIn.forEach((mainContent, i) => {
  const anim = gsap.fromTo(
    mainContent,
    { opacity: 0 },
    { duration: 1, opacity: 1, stagger: 0.2 }
  );
  ScrollTrigger.create({
    trigger: mainContent,
    animation: anim,
    toggleActions: "play",
    once: true,
    duration: 1,
    ease: Power4.easeOut,
  });
});

// ============== cursor view case =====================
const cursorBee = document.querySelector(".cursor-bee");
const cursorEye = document.querySelector(".cursor-eye");
let scale = 1;

function mousemoveHandler(e) {
  const target = e.target;
  const tl = gsap.timeline({
    defaults: {
      x: e.clientX,
      y: e.clientY,
      ease: "power2.out",
    },
  });

  if (target.tagName.toLowerCase() === "img" && target.closest(".image-grid")) {
    tl.to(cursorBee, {
      opacity: 0,
    }).to(
      cursorEye,
      {
        opacity: 1,
        zIndex: 5,
      },
      "-=0.5"
    );
  } else {
    if (target.classList.contains("line-animation")) {
      scale = 4;
    } else {
      scale = 1;
    }

    tl.to(cursorBee, {
      opacity: 1,
      scale: scale,
    }).to(
      cursorEye,
      {
        opacity: 0,
      },
      "-=0.5"
    );
  }
}

function mouseleaveHandler() {
  gsap.to(cursorBee, {
    opacity: 0,
  });
}

document.addEventListener("mousemove", mousemoveHandler);
document.addEventListener("mouseleave", mouseleaveHandler);

// ====== parallel background ==========
let getRatio = (el) =>
  window.innerHeight / (window.innerHeight + el.offsetHeight);

gsap.utils.toArray(".bg-parallax-wrapper").forEach((section, i) => {
  section.bg = section.querySelector(".bg-parallax");
  let mediaQuery = window.matchMedia("(min-width: 767px)");

  if (mediaQuery.matches) {
    // screen larger than 767px
    gsap.fromTo(
      section.bg,
      {
        backgroundPosition: () =>
          i ? `50% ${-window.innerHeight * getRatio(section)}px` : "50% 0px",
      },
      {
        backgroundPosition: () =>
          `50% ${window.innerHeight * (1 - getRatio(section))}px`,
        ease: "none",
        scrollTrigger: {
          trigger: section,
          start: () => (i ? "top bottom" : "top top"),
          end: "bottom top",
          scrub: true,
          invalidateOnRefresh: true, // to make it responsive
        },
      }
    );
  }
});

// ============ swiper slider - testimonial slider ==========
$(document).ready(function () {
    // Swiper: Slider
    var swiper = new Swiper(".testimonialWrapper", {
      loop: true,
      slidesPerView: 1,
      pagination:'.swiper-pagination',
      paginationClickable: true,
      spaceBetween: 20,
      breakpoints: {
        1920: {
          slidesPerView: 1,
          spaceBetween: 30,
        },
        1028: {
          slidesPerView: 1,
          spaceBetween: 30,
        },
        480: {
          slidesPerView: 1,
          spaceBetween: 10,
        },
      },
    });
});
// ================= faq (accordion) section ===============
const accordionBtns = document.querySelectorAll(".accordion");

accordionBtns.forEach((accordion) => {
  accordion.onclick = function () {
    this.classList.toggle("is-open");

    let content = this.nextElementSibling;

    if (content.style.maxHeight) {
      //this is if the accordion is open
      content.style.maxHeight = null;
    } else {
      //if the accordion is currently closed
      content.style.maxHeight = content.scrollHeight + "px";
    }
  };
});

// ============ javascript counter =============
function visible(partial) {
  let $t = partial,
    $w = jQuery(window),
    viewTop = $w.scrollTop(),
    viewBottom = viewTop + $w.height(),
    _top = $t.offset().top,
    _bottom = _top + $t.height(),
    compareTop = partial === true ? _bottom : _top,
    compareBottom = partial === true ? _top : _bottom;

    console.log($t);

  return (
    compareBottom <= viewBottom && compareTop >= viewTop && $t.is(":visible")
  );
}

$(window).scroll(function () {
  const myCounter = document.querySelector('.count-digit');

  if(myCounter !== null){
    if (visible($(".count-digit"))) {
      if ($(".count-digit").hasClass("counter-loaded")) return;
      $(".count-digit").addClass("counter-loaded");
  
      $(".count-digit").each(function () {
        if ($(this).html() == Math.floor($(this).html())) {
          var $this = $(this);
          jQuery({ Counter: 0 }).animate(
            { Counter: $this.text() },
            {
              duration: 1500,
              easing: "swing",
              step: function () {
                $this.text(Math.trunc(this.Counter) + 1);
              },
            }
          );
        } else {
          var $this = $(this);
          jQuery({ Counter: 0 }).animate(
            { Counter: $this.text() },
            {
              duration: 1500,
              easing: "swing",
              step: function () {
                $this.text(((this.Counter * 10) / 10).toFixed(1));
              },
            }
          );
        }
      });
    }
  }
});

// ============== checkbox is checked ============
$("#agree-concent").click(function () {
  if (!$(this).is(":checked")) {
    $("#btncheck").attr("disabled", "disabled").removeClass("enable");
  } else $("#btncheck").removeAttr("disabled").addClass("enable");
});

// ========== work portfolio filter ==========
$(".filters ul li").click(function () {
  $(".filters ul li").removeClass("active");
  $(this).addClass("active");

  var data = $(this).attr("data-filter");
  $grid.isotope({
    filter: data,
  });
});

var $grid = $(".grid");

if($grid !== null){
  $grid.imagesLoaded().progress(function () {
    $grid.isotope("layout");
    $grid.isotope({
      itemSelector: ".all",
      percentPosition: true,
      masonry: {
        columnWidth: ".all",
      },
    });
  });
}